<template>
  <div>
    <el-dialog
      :visible.sync="dialogLoginVisible"
      center
      width="0%"
      append-to-body
    >
      <div class="login" v-if="LoginRegister">
        <div class="login-box">
          <span class="login-title">登录</span>
          <i class="el-icon-close" @click="closeLogin()"></i>
          <div class="login-box-content">
            <el-tabs
              stretch
              class="login-tabs"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane
                label="账号密码登录"
                name="accountLogin"
                v-if="setting.accountLogin"
              >
                <div class="loginCode">
                  <div class="login-form">
                    <!-- 账号密码登录 -->
                    <el-form
                      :model="loginForm"
                      status-icon
                      :rules="rules"
                      ref="loginForm"
                      class="demo-ruleForm"
                    >
                      <el-form-item prop="username">
                        <el-input
                          v-model="loginForm.username"
                          placeholder="请输入账号"
                        ></el-input>
                      </el-form-item>

                      <el-form-item prop="password">
                        <el-input
                          placeholder="请输入密码"
                          type="password"
                          v-model="loginForm.password"
                          autocomplete="off"
                          show-password
                        ></el-input>
                      </el-form-item>

                      <div class="sign-operation">
                        <a class="el-link el-link--default">
                          <!-- 忘记密码 -->
                          <span class="el-link--inner"></span>
                        </a>
                        <a
                          class="el-link el-link--default"
                          @click="LoginRegister = false"
                        >
                          <span class="el-link--inner">免费注册</span>
                        </a>
                      </div>
                      <el-form-item>
                        <div class="form-agreement">
                          <span><el-radio></el-radio></span>
                          <span>请您阅读并同意</span>
                          <span class="spanb" @click="dialogUseAgreement = true"
                            >用户协议</span
                          >
                          和
                          <span
                            class="spanb"
                            @click="dialogPrivacyAgreement = true"
                            >隐私协议</span
                          >
                        </div>
                      </el-form-item>

                      <el-form-item style="text-align: center">
                        <el-button
                          style="width: 100%"
                          type="primary"
                          @click="handleLogin('loginForm')"
                          v-preventClick
                          >登录</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-tab-pane>

              <el-tab-pane
                label="短信登录"
                name="phoneLogin"
                v-if="setting.phoneLogin"
              >
                <!-- 手机号验证登录 -->
                <div class="loginCode">
                  <div class="login-form">
                    <el-form
                      :model="loginForm"
                      status-icon
                      :rules="rules"
                      ref="phoneLoginForm"
                      class="demo-ruleForm"
                    >
                      <el-form-item prop="phoneNumber">
                        <el-input
                          v-model="loginForm.phoneNumber"
                          placeholder="请输入手机号"
                        ></el-input>
                      </el-form-item>

                      <el-form-item prop="smsVerificationCode">
                        <el-input
                          placeholder="请输入验证码"
                          v-model="loginForm.smsVerificationCode"
                        >
                          <template
                            slot="append"
                            style="
                              background-color: #0065fd;
                              margin: 0px 0px 10px 0px;
                            "
                          >
                            <el-button
                              type="primary"
                              :disabled="countdown > 0"
                              @click="startCountdown()"
                              v-preventClick
                              >{{ buttonText }}</el-button
                            >
                          </template>
                        </el-input>
                      </el-form-item>

                      <el-form-item>
                        <div class="form-agreement">
                          <span><el-radio></el-radio></span>
                          <span>请您阅读并同意</span>
                          <span class="spanb" @click="dialogUseAgreement = true"
                            >用户协议</span
                          >
                          和
                          <span
                            class="spanb"
                            @click="dialogPrivacyAgreement = true"
                            >隐私协议</span
                          >
                        </div>
                      </el-form-item>

                      <el-form-item style="text-align: center">
                        <el-button
                          style="width: 100%"
                          type="primary"
                          @click="phoneLogin('phoneLoginForm')"
                          v-preventClick
                          >登录</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>

            <!-- 快捷登录 -->
            <div class="third-login" v-if="this.setting.quickLogin">
              <el-divider>快捷登录</el-divider>
              <ul class="third-list">
                <li class="third-item">
                  <div class="login-quick">
                    <a class="qq" href="#" @click="jhLoginForm('qq')">
                      <img
                        class="login-icon"
                        src="@/assets/icon/qq.svg"
                        draggable="false"
                        @click="wxLogin()"
                      />
                    </a>
                    <a class="wx" href="#" @click="jhLoginForm('wx')">
                      <img
                        class="login-icon"
                        src="@/assets/icon/login_weixin.svg"
                        draggable="false"
                        @click="wxLogin()"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 注册 -->
      <div class="login" v-else>
        <div class="login-box">
          <span class="login-title"> 注册 </span>

          <i class="el-icon-close" @click="closeLogin()"></i>
          <div class="login-box-content">
            <div class="loginCode">
              <div class="login-form">
                <el-form
                  :model="loginForm"
                  status-icon
                  :rules="rules"
                  ref="registerForm"
                >
                  <el-form-item prop="username">
                    <el-input
                      v-model="loginForm.username"
                      placeholder="请输入账号"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="password">
                    <el-input
                      placeholder="请输入密码"
                      type="password"
                      v-model="loginForm.password"
                      autocomplete="off"
                      show-password
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="checkPass">
                    <el-input
                      placeholder="请确认密码"
                      type="password"
                      v-model="loginForm.checkPass"
                      autocomplete="off"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <div class="sign-operation">
                    <a class="el-link el-link--default">
                      <span class="el-link--inner"></span>
                    </a>
                    <a
                      class="el-link el-link--default"
                      @click="LoginRegister = true"
                    >
                      <span class="el-link--inner">已有账号 立即登录</span>
                    </a>
                  </div>
                  <el-form-item>
                    <div class="form-agreement">
                      <span><el-radio></el-radio></span>
                      <span>请您阅读并同意</span>
                      <span class="spanb" @click="dialogUseAgreement = true"
                        >用户协议</span
                      >
                      和
                      <span class="spanb" @click="dialogPrivacyAgreement = true"
                        >隐私协议</span
                      >
                    </div>
                  </el-form-item>

                  <el-form-item style="text-align: center">
                    <el-button
                      style="width: 100%"
                      type="primary"
                      @click="handleRegister('registerForm')"
                  
                      >注册</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="用户协议"
      :visible.sync="dialogUseAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.useAgreement"></div>
    </el-dialog>

    <el-dialog
      title="隐私协议"
      :visible.sync="dialogPrivacyAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.privacyAgreement"></div>
    </el-dialog>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserId } from "@/utils/auth";
import { getSysXieYi } from "@/api/index";
import { getScanLogin, sendPhoneCode } from "@/api/user";
import { mapState } from "vuex";

export default {
  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入不少于6位的密码"));
      } else {
        if (this.loginForm.checkPass !== "") {
          this.$refs.registerForm.validateField("checkPass");
        }
        callback();
      }
    };

    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateCheckPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };

    var validateCheckVerificationCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      getUserId: getUserId(),
      rules: {
        username: [{ validator: validateUserName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validateCheckPass, trigger: "blur" }],
        phoneNumber: [{ validator: validateCheckPhone, trigger: "blur" }],
        smsVerificationCode: [
          { validator: validateCheckVerificationCode, trigger: "blur" },
        ],
      },
      dialogLoginVisible: true,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
      },

      xieyiObj: {},
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      inviter_code: "",
      //true是登录 false是注册
      LoginRegister: true,
    };
  },
  computed: {
    ...mapState({
      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },

  mounted() {
    // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = "accountLogin";
    } else if (this.setting.phoneLogin) {
      this.activeName = "phoneLogin";
    } else {
      this.activeName = "accountLogin";
    }
  },
  created() {
    this.loadXieYi();
  },
  methods: {
    /**
     * 账号密码登录
     */
    handleLogin(formName) {
      console.log("=============================");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/login", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });
              this.$router.go(0);
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 手机号登录
     */
    phoneLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/phoneLogin", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });
              this.$router.go(0);
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 注册
     *
     * */
    handleRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/register", this.loginForm)
            .then(() => {
              this.$store.dispatch("user/login", this.loginForm).then(() => {
                Toast.success({
                  message: "注册成功",
                });
                this.dialogLoginVisible = false;

                this.$router.go(0);
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 切换登录
     */
    handleClick() {
      this.$refs.loginForm.resetFields();
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    closeLogin() {
      this.dialogLoginVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style scoped>
.login-title {
  display: block;
  white-space: nowrap;
  font-weight: 550;
  color: #333;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  z-index: 999;
}
/* .third-login {
  padding-top: 10px;
} */

.third-login .third-list {
  text-align: center;
}

.third-login .third-list .third-item {
  /* display: inline-block; */
  white-space: nowrap;
}

.third-login .third-list .third-item .iconfont {
  font-size: 30px;
}

.third-login .third-list .third-item .el-link:hover .iconfont {
  color: #409eff !important;
}

.third-login .third-list .third-item + .third-item {
  margin-left: 10px;
}

.login-quick {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.login-quick a {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  transition: 0.2s;
}

.login-quick a .login-icon {
  display: flex;
  width: 35px;
  height: 35px;
  color: #fff;
}

.login-quick a + a {
  margin-left: 12px;
}

.login-icon {
  font-size: 18px;
  margin-right: 6px;
}

.login-tabs {
  padding: 10px 0px 0px 0px;
}

.login-tabs .el-tabs__header {
  background-color: #ffffff; /* 设置背景颜色 */
  border-radius: 20px; /* 设置椭圆形状 */
  margin-bottom: 20px;
  padding: 0px;
  width: 170px;
  text-align: center;
}

.login-tabs .el-tabs__item {
  color: #606266; /* 设置文字颜色 */
  border: none; /* 去掉边框 */
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  padding: 0px;
  width: 170px;
  text-align: center;
  font-size: 16px;
  /* padding: 0 15px; */
}

.sign-operation {
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  padding-bottom: 18px;
  padding-top: 0;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
