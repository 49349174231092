<template>
  <div class="callback-container">
    <div class="bind-box">
      <div class="main-wrap" style="line-height: 30px" v-if="!bindVisible">
        <div class="logo"><h4>首次登录绑定</h4></div>
        <div class="btns">
          <div class="btn1">
            <button
              @click="ChuangJianAccount()"
              type="button"
              class="el-button el-button--danger el-button--default"
            >
              <span>新用户，自动创建一个账号</span>
            </button>
            <span class="tip">新用户初始密码为123456</span>
          </div>
          <button
            @click="bindVisible = true"
            type="button"
            class="el-button el-button--primary el-button--default"
          >
            <span>老用户，立即绑定一个账号</span>
          </button>
        </div>
      </div>
      <div class="user-wrap" v-if="bindVisible">
        <div class="el-page-header">
          <div class="el-page-header__left" @click="bindVisible = false">
            <i class="el-icon-back"></i>
            <div class="el-page-header__title">返回</div>
          </div>
          <div class="el-page-header__content">第三方登录账号绑定</div>
        </div>
        <div class="user-form">
          <el-form>
            <el-form-item>
              <el-input
                prefix-icon="el-icon-search"
                v-model="loginForm.username"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-input
                prefix-icon="el-icon-search"
                v-model="loginForm.password"
                placeholder="请输入密码"
                show-password
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="BangDingAccount()"
                style="width: 100%"
                v-preventClick
                >立即绑定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bindAccount, createAccount } from "@/api/user";
import { setToken, setUserId } from "@/utils/auth";

export default {
  data() {
    return {
      goodsLabelList: [],
      bindVisible: false,
      loginForm: {
        username: "",
        password: "",
        socialuid: undefined,
        type: undefined,
      },
    };
  },
  components: {},
  created() {},
  methods: {
    /**
     * 创建一个新账号
     */
    ChuangJianAccount() {
      let data = {
        socialuid: this.$route.query.socialuid,
        type: this.$route.query.type,
      };
      new Promise((resolve, rejust) => {
        createAccount(data)
          .then((res) => {
            if (res.status === 0) {
              const { token, userId } = res.result;
              setToken(token);
              setUserId(userId);
              this.$router.push("/");
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 绑定账号
     */
    BangDingAccount() {
      this.loginForm.socialuid = this.$route.query.socialuid;
      this.loginForm.type = this.$route.query.type;

      new Promise((resolve, rejust) => {
        bindAccount(this.loginForm)
          .then((res) => {
            console.log("=========" + res);
            if (res.status === 0) {
              const { token, userId } = res.result;
              setToken(token);
              setUserId(userId);
              this.$router.push("/");
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style scoped>
.callback-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #0083ff;
  background-image: url(../../assets/image/login_bg.png);
  background-size: cover;
  background-position: 50%;
}
.callback-container .bind-box {
  position: absolute;
  padding: 38px 30px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* PC端样式 */
@media (min-width: 992px) {
  .callback-container .bind-box {
    width: 550px;
    height: 350px;
  }
}

/* 移动端样式 */
@media (max-width: 991px) {
  .callback-container .bind-box {
    width: 90%;
    height: auto;
    max-width: 550px;
    min-height: 250px;
  }
}
.callback-container .bind-box .main-wrap .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  border-bottom: 1px solid #dcdfe6;
}
.callback-container .bind-box .main-wrap .logo h4 {
  font-size: 16px;
  color: #ea0808;
  font-weight: 700;
}
.callback-container .bind-box .main-wrap .btns .btn1,
.callback-container .bind-box .main-wrap .btns {
  display: flex;
  flex-direction: column;
}
.callback-container .bind-box .main-wrap .btns .btn1 {
  margin-bottom: 40px;
}
.callback-container .bind-box .main-wrap .btns .btn1 .tip {
  text-align: center;
  color: #ea0808;
}
.callback-container .bind-box .user-wrap .user-form {
  padding-top: 50px;
}
</style>
