<template>
  <div>
    <van-sticky>
      <div class="header_2_style">
        <div
          class="navbar-wrapper"
          style="--theme-color: #ff2c2c; --themeColor: #ff2c2c"
        >
          <div class="nav-main__wrapper" style="background: rgb(255, 255, 255)">
            <div class="navbar-main pc_style">
              <div class="navbar-logo-common">
              <a href="/">
                <img
                  style="height: 100%; width: 100%"
                  :src="webSetting.detail.webLogo"
                />
              </a>
              </div>

              <div class="navbar-list">
                <div class="navbar-left nav-maxwidth">
                  <div class="nav-item know-nav">
                    <a href="/">首页</a>

                    <a v-for="(item, index) in module.list" :href="item.link">
                      <span class="on">
                        <span> {{ item.name }}</span>
                      </span>
                    </a>
                  </div>
                </div>

                <!-- 已登录 -->
                <template v-if="this.loginStatus">
                  <div
                    class="navbar-right"
                    style="font-size: 14px; color: rgb(102, 102, 102)"
                  >
                    <a-dropdown>
                      <a
                        class="ant-dropdown-link"
                        @click="(e) => e.preventDefault()"
                      >
                        欢迎您： <a href="#">{{ username }}</a>
                        <a-icon type="down" />
                      </a>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a href="javascript:;" @click="handleLogout()">
                            退出登录
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>

                    <div
                      class="icon-wrapper"
                      style="font-size: 14px"
                      @click="toMyOrder()"
                    >
                      <div>我的订单</div>
                    </div>
                    <div
                      @click="toCustomer()"
                      class="icon-wrapper leaning-icon opretor"
                    >
                      <div>联系客服</div>
                    </div>
                    <div class="opretor">
                      <a-input
                        placeholder="请输入搜索关键词"
                        v-model="goodsName"
                        @keyup.enter.native="searchgoods"
                      />
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div
                    class="navbar-right"
                    style="font-size: 14px; color: rgb(102, 102, 102)"
                  >
                    <div
                      class="theme-customize-font no-login"
                      @click="clickLogin()"
                    >
                      <span class="func">登录</span>
                    </div>

                    <div class="opretor">
                      <a-input
                        placeholder="请输入搜索关键词"
                        v-model="goodsName"
                        @keyup.enter.native="searchgoods"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-sticky>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import PLogin from "@/pages/pc/common/login";

export default {
  props: ["module"],
  components: {
    PLogin,
  },
  data() {
    return {
      selectedIndex: 0,
      navList: [],
      loginStatus: false,
      goodsName: "",
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
      },
      dialogLoginVisible: false,
      dialogRegisterVisible: false,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      webSetting: (state) => state.index.webSetting,
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
      // normalNewsList: (state) => state.index.normalNewsList,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      brandListByCategoryId: (state) => state.index.brandListByCategoryId,
    }),
  },
  created() {
    //验证是否登录
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;
        this.$store.dispatch("user/info");
      }
    },

    // 跳转到详情页面
    searchgoods() {
      if (this.goodsName == "" || this.goodsName == undefined) {
        this.$message("请输入搜索关键词");
      } else {
        this.$router.push({
          name: "plist",
          query: { goodsName: this.goodsName },
        });
      }
    },

    /**
     * 登录
     */
    clickLogin() {
      // this.dialogLoginVisible = !this.dialogLoginVisible;
      this.$router.push("/plogin");
    },

    /**
     * 退出登录
     */
    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          location.reload();
          this.$message({
            message: "注销成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    //跳转到我的订单
    toMyOrder() {
      const hasToken = getToken();
      if (!hasToken) {
        this.dialogLoginVisible = !this.dialogLoginVisible;
        return;
      }
      this.$router.push({ path: "/porder" });
    },

    //跳转到个人中心
    toUserInfo() {
      const hasToken = getToken();
      if (!hasToken) {
        this.dialogLoginVisible = !this.dialogLoginVisible;
        return;
      }
      this.$router.push({ path: "/user_info" });
    },

    /**
     * 跳转到客服
     */
    toCustomer() {
      this.$router.push({ path: "/customer" });
    },

    /**
     * 跳转到首页
     */
    toIndex() {
      this.$router.push({ path: "/index" });
    },

    isActive(tag) {
      console.log(this.$route.path);
      // return tag === this.$route.path;
      return tag.includes(this.$route.path);
    },

    /**
     * 跳转到系统页面
     */
    toNavUrl(v) {
      // var that = this;

      // this.selectedIndex = index;
      if (v.navType === "1") {
        // if (this.loginStatus) {
        this.$router.push({ path: v.navUrl });
        // } else {
        //   that.clickLogin();
        // }
      } else {
        window.open(v.navUrl, "_blank");
      }
    },
  },
};
</script>

<style>
.navbar-wrapper {
  width: 100%;
  background: #fff;
  height: 72px;
}

.navbar-wrapper.navbar-wrapper__active .nav-main__wrapper {
  position: fixed;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
}

.navbar-wrapper > .nav-main__wrapper {
  width: 100%;
  height: 72px;
  background: #fff;
  z-index: 999;
  left: 0;
  top: 0;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main > .navbar-logo {
  width: 138px;
  flex-shrink: 0;
  height: 100%;
  background: url(https://assets.cdn.xiaoeknow.com/decorate/pc-client/1.82.79/img/logo.svg)
    no-repeat;
  background-position: 0 19px;
  background-size: contain;
  margin-right: 142px;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main .navbar-logo-common {
  flex-shrink: 0;
  height: 50px;
  margin: 16px 40px 16px 0;
  line-height: 40px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  .navbar-logo-common.is-tencent-inte-school {
  width: 40px;
  margin: 16px 12px 16px 0;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main .navbar-shopname-common {
  margin-right: 80px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main > div,
.navbar-wrapper > .nav-main__wrapper > .navbar-main a {
  display: inline-block;
  height: 100%;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main > .navbar-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex: 1;
  position: relative;
  z-index: 105;
}

.navbar-wrapper > .nav-main__wrapper > .navbar-main > .navbar-list > div {
  float: left;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left {
  height: 72px;
  flex: 1;
  white-space: nowrap;
  position: relative;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .nav-item
  > a {
  color: rgb(0 0 0);
  line-height: 72px;
  font-size: 16px;
  margin-right: 24px;
  visibility: visible;
  opacity: 1;
  cursor: pointer;
  transform-origin: center;
  transform: scale(1);
  transition: transform 0.3s ease-out, visibility 0.3s ease-out,
    opacity 0.3s ease-out;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .nav-item
  > a.active,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .nav-item
  > a:hover {
  color: #2a75ed;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .nav-item:last-child
  > a {
  margin-right: 40px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-nav {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #333;
  font-weight: bolder;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .hiddenNavItem
  a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .left-list-animate {
  transform: scale(0.7);
  visibility: hidden;
  opacity: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  a:first-child {
  transition-delay: 0.14s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  a:nth-child(2) {
  transition-delay: 0.12s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  a:nth-child(3) {
  transition-delay: 0.1s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  a:nth-child(4) {
  transition-delay: 0.08s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  a:nth-child(5) {
  transition-delay: 0.06s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search {
  position: absolute;
  right: 17px;
  top: 18px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  color: #888;
  font-size: 14px;
  transition: all 0.1s linear;
  z-index: 9;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div {
  height: inherit;
  transform: translateX(40px);
  transition: all 0.3s cubic-bezier(0.22, 0.65, 0.1, 0.79);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div
  > i {
  line-height: 36px;
  margin-right: 8px;
  font-size: 14px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div
  .search-close {
  font-size: 14px;
  position: absolute;
  right: 18px;
  line-height: 36px;
  cursor: pointer;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div
  > input {
  width: calc(100% - 24px);
  height: inherit;
  outline: none;
  caret-color: #888;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  border-radius: 4px !important;
  color: #444 !important;
  width: 216px !important;
  padding: 6px 44px 6px 12px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div
  > input::-webkit-input-placeholder {
  color: #888;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > div
  > input:focus {
  border-color: #428eff !important;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .know-navbar-search
  > .search-bar-main-enter {
  transform: translateX(0);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search {
  position: absolute;
  left: 0;
  top: 18px;
  width: 636px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  background: #fff;
  color: #888;
  font-size: 14px;
  transition: all 0.1s linear;
  z-index: 9;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search
  > div {
  height: inherit;
  transform: translateX(40px);
  transition: all 0.3s cubic-bezier(0.22, 0.65, 0.1, 0.79);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search
  > div
  > i {
  line-height: 32px;
  margin-right: 8px;
  font-size: 14px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search
  > div
  > input {
  width: calc(100% - 24px);
  height: inherit;
  border: none;
  outline: none;
  caret-color: #888;
  font-size: 14px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search
  > div
  > input::-webkit-input-placeholder {
  color: #888;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-search
  > .search-bar-main-enter {
  transform: translateX(0);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .isEn-search {
  width: 560px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search {
  height: -moz-max-content !important;
  height: max-content !important;
  width: 216px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 0 16px;
  box-sizing: border-box;
  transition: opacity 0.1s linear;
  z-index: 9;
  box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.1);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > p {
  font-size: 12px;
  color: #888;
  margin: 12px 0;
  padding: 0 24px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul {
  padding-left: 0;
  font-size: 14px;
  color: #353535;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  > li {
  height: 32px;
  line-height: 32px;
  padding: 0 24px;
  cursor: pointer;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  > li:hover {
  background: #eee;
  color: #2a75ed;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  li:first-child {
  transition-delay: 0.05s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  li:nth-child(2) {
  transition-delay: 0.07s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  li:nth-child(3) {
  transition-delay: 0.09s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  li:nth-child(4) {
  transition-delay: 0.11s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  > ul
  li:nth-child(5) {
  transition-delay: 0.13s;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  .hot-init {
  transform: translateX(60px);
  transition: all 0.3s ease-out;
  opacity: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-left
  .navbar-hot-search
  .hot-enter {
  transform: translateX(0);
  opacity: 1;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  .nav-maxwidth {
  max-width: var(--max-width-style);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right {
  flex-shrink: 0;
  text-align: right;
  line-height: 72px;
  height: 72px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  .backstage-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 36px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  .backstage-wrapper
  .backstage {
  width: 116px;
  height: 36px;
  background: #105cfb;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  .backstage-wrapper
  .backstage
  img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right.is-tencent-inte-school {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > a,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > div,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > i {
  float: right;
  margin-left: 24px;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  line-height: 72px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .iconfont {
  font-size: 16px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > a {
  color: #353535;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > a:hover {
  color: #2a75ed;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > i {
  color: #888;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper {
  height: 72px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper:hover {
  color: var(--themeColor);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > i {
  font-size: 16px;
  line-height: 72px;
  color: #888;
  margin-right: 5px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .leaning-icon:after {
  content: "";
  display: block;
  width: 1px;
  height: 16px;
  background-color: var(--line-after-style);
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .opretor:after {
  opacity: 0.3;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper {
  display: flex;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  i {
  margin-right: 5px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper:hover {
  color: var(--themeColor);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper:after {
  content: "";
  display: block;
  width: 1px;
  height: 16px;
  background-color: var(--line-after-style);
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper {
  position: relative;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > .tit-box,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > .tit-box {
  position: absolute;
  top: 59px;
  left: 50%;
  z-index: 9;
  margin-left: -36px;
  width: 72px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: #666;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(51, 51, 51, 0.08),
    0 2px 6px -4px rgba(51, 51, 51, 0.12);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > .small-tit-box,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > .small-tit-box {
  width: 44px;
  margin-left: -22px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > .tit-box:before,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > .tit-box:before {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  top: -7px;
  right: 26px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #666;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > .small-tit-box:before,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > .small-tit-box:before {
  right: 12px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper:hover:before,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper:hover
  > .hidden-tit,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper:hover:before,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper:hover
  > .hidden-tit {
  display: block;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > .hidden-tit,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > .hidden-tit {
  display: none;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .icon-wrapper
  > i,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .search-wrapper
  > i {
  font-size: 16px;
  line-height: 72px;
  color: #888;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .no-login {
  color: #2a75ed;
  cursor: default;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .no-login
  .func {
  cursor: pointer;
  padding: 5px 22px;
  border: 1px solid;
  border-radius: 4px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .no-login
  .slash {
  margin: 0 6px;
  color: #b2b2b2;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper {
  padding-top: 18px;
  position: relative;
  margin-right: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper.is-tencent-inte-school {
  display: flex;
  align-items: center;
  padding-top: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper.is-tencent-inte-school
  .tencent-inte-student-name {
  height: 64px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper:before {
  content: "";
  position: absolute;
  width: 64px;
  height: 27px;
  right: 0;
  bottom: -20px;
  display: none;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  .navbar-info {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 32px;
  color: #d1dbed;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper:hover:before,
.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper:hover
  > .hidden {
  display: block;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .hidden {
  display: none;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center {
  text-align: left;
  position: absolute;
  top: 120%;
  right: 0;
  width: 200px;
  background: #fff;
  padding: 15px 0;
  font-size: 16px;
  z-index: 9;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08),
    0 6px 12px 0 rgba(0, 0, 0, 0.08);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center
  > div {
  margin: 15px 26px 15px 24px;
  border: 1px solid #eee;
  border-bottom: none;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center
  > li {
  line-height: 40px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center
  > li
  > a {
  padding-left: 24px;
  width: 100%;
  color: #666;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center
  > li
  > a
  > i {
  margin-right: 5px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center
  > li:hover {
  background: #fafafa;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap {
  padding-top: 10px;
  cursor: default;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training {
  text-align: left;
  position: absolute;
  margin-top: 20px;
  top: 100%;
  right: 0;
  width: 265px;
  background: #fff;
  font-size: 16px;
  z-index: 9;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08),
    0 6px 12px 0 rgba(0, 0, 0, 0.08);
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  > div {
  margin: 15px 26px 15px 24px;
  border: 1px solid #eee;
  border-bottom: none;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  > li {
  line-height: 40px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  > li
  > a {
  padding-left: 24px;
  width: 100%;
  color: #666;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  > li
  > a
  > i {
  margin-right: 5px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  > li:hover {
  background: #fafafa;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .user-info {
  display: flex;
  margin: 0;
  border: none;
  height: 82px;
  align-items: center;
  padding-left: 20px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .user-info
  img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 12px;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .user-info
  div {
  display: inline-block;
  line-height: 1;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .user-info
  div
  .training-name {
  font-size: 14px;
  color: #333;
  margin: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .user-info
  div
  .training-branch {
  font-size: 12px;
  color: #999;
  margin: 4px 0 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .btn-training {
  cursor: pointer;
  margin: 0;
  line-height: 1;
  text-align: center;
  padding: 20px 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .btn-training
  div {
  margin: 0;
  line-height: 1;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training
  .btn-training
  .btn-training-logout {
  font-size: 14px;
  color: #999;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-right
  > .person-center__wrapper
  > .navbar-person-center-training-wrap
  .navbar-person-center-training:before {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  top: -7px;
  right: 10px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-hot-search-init {
  visibility: hidden;
  opacity: 0;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .navbar-hot-search-enter {
  visibility: visible;
  opacity: 1;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .search-init {
  opacity: 0;
  visibility: hidden;
}

.navbar-wrapper
  > .nav-main__wrapper
  > .navbar-main
  > .navbar-list
  > .search-show {
  opacity: 1;
  visibility: visible;
}

.nav-item {
  position: relative;
  float: left;
}

.nav-item:hover .sub-nav-wrap {
  visibility: visible;
}
</style>
