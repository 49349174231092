<!-- pc登录页 -->
<template>
  <div class="login_p">
    <div class="page-account">
      <div class="page-account-container">
        <div class="page-account-top">
          <div class="page-account-top-logo">
            <img :src="webSetting.detail.webLogo" alt="logo" />
          </div>
          <div class="page-account-top-desc">欢迎登录</div>
        </div>


        <div style="min-height: 340px;">
          <a-tabs default-active-key="2" @change="callback">

            <!-- 手机号登录 -->
            <a-tab-pane key="1" tab="短信登录" v-if="setting.phoneLogin">
              <a-form-model style="margin-top: 10px" ref="loginForm" :model="loginForm" :rules="loginRules2">
                <a-form-model-item prop="phoneNumber">
                  <a-input placeholder="请输入手机号" v-model="loginForm.phoneNumber" size="large">
                    <a-icon slot="prefix" type="mobile" />
                  </a-input>
                </a-form-model-item>

                <a-form-model-item prop="smsVerificationCode">
                  <a-input-search placeholder="请输入验证码" size="large" v-model="loginForm.smsVerificationCode"
                    @search="startCountdown">
                    <a-icon slot="prefix" type="key" />

                    <a-button slot="enterButton" style="color: #69b1ff; font-size: 14px" :disabled="countdown > 0" v-preventClick>
                      {{ buttonText }}
                    </a-button>
                  </a-input-search>
                </a-form-model-item>
              </a-form-model>

              <div class="login-bottom">
                <a-checkbox checked="true">
                  已阅读并同意
                  <el-button type="text" @click="dialogUseAgreement = true">
                    《用户协议》
                  </el-button>
                  <el-button type="text" @click="dialogPrivacyAgreement = true">
                    《隐私权政策》
                  </el-button>
                </a-checkbox>
              </div>
              <a-button type="primary" block style="height: 40px" @click.native.prevent="phoneLogin" v-preventClick>
                立即登录
              </a-button>
            </a-tab-pane>


            <!-- 账号密码登录 -->
            <a-tab-pane key="2" tab="账号密码登录" v-if="setting.accountLogin">
              <a-form-model style="margin-top: 10px" ref="loginForm" :model="loginForm" :rules="loginRules">
                <a-form-model-item prop="username">
                  <a-input placeholder="请输入登录账号" v-model="loginForm.username" size="large">
                    <a-icon slot="prefix" type="user" />
                  </a-input>
                </a-form-model-item>

                <a-form-model-item prop="password">
                  <a-input placeholder="请输入登录密码" v-model="loginForm.password" size="large">
                    <a-icon slot="prefix" type="lock" />
                  </a-input>
                </a-form-model-item>
              </a-form-model>

              <a-checkbox checked="true">
                已阅读并同意
                <el-button type="text" @click="dialogUseAgreement = true">
                  《用户协议》
                </el-button>
                <el-button type="text" @click="dialogPrivacyAgreement = true">
                  《隐私权政策》
                </el-button>
              </a-checkbox>

              <a-button type="primary" block style="height: 40px" @click.native.prevent="handleLogin" v-preventClick>
                立即登录
              </a-button>

              <div class="sign-operation">
                <router-link to="/forget">
                  <a-button type="link" style="padding: 0px">
                    忘记密码
                  </a-button>
                </router-link>

                <router-link to="/pRegister">
                  <a-button type="link" style="padding: 0px">
                    免费注册
                  </a-button>
                </router-link>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>


        <div class="dx2-global-footer">
          <!-- 快捷登录 -->
          <div class="third-login" v-if="this.setting.quickLogin">
            <a-divider>
              <span style="font-size: 12px">快捷登录</span>
            </a-divider>

            <div style="display: flex; justify-content: center">
              <div v-for="(item, index) in quickLoginList" :key="index" @click="jhLoginForm(item.type)"
                style="padding: 0px 15px">

                <img v-if="item.type === 'qq'" class="login_icon" src="@/assets/icon/qq.svg" />

                <img v-if="item.type === 'wx'" class="login_icon" src="@/assets/icon/login_weixin.svg" />

                <img v-if="item.type === 'alipay'" class="login_icon" src="@/assets/icon/icon_zhifubao.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal title="用户协议"  v-model="dialogUseAgreement" :footer="null" width="60%">
      <div v-html="xieyiObj.useAgreement"></div>
    </a-modal>


    <a-modal title="隐私协议"  v-model="dialogPrivacyAgreement" :footer="null" width="60%">
      <div v-html="xieyiObj.privacyAgreement"></div>
    </a-modal>


    <!-- 首页弹框 -->
    <a-modal title="系统公告" v-model="loginNoticeVisible" on-ok="handleOk">
      <div class="ql-editor" v-html="loginNoticeContent" />

      <!-- 底部按钮 -->
      <template slot="footer">
        <a-button key="submit" type="danger" @click="loginNoticeVisible = false">
          我知道了
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserId } from "@/utils/auth";
import { getSysXieYi, getLoginNotice, getQuickLogin } from "@/api/index";
import {
  getScanLogin,
  sendPhoneCode,
  resetPwdVerifyCode,
  pwdReset,
} from "@/api/user";
import { mapState } from "vuex";

// import Captcha from "@/components/captcha/CaptchaB.vue";
import Verify from "@/components/verifition/Verify";

export default {
  components: { Verify },

  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入不少于6位的密码"));
      } else {
        if (this.loginForm.checkPass !== "") {
          this.$refs.registerForm.validateField("checkPass");
        }
        callback();
      }
    };

    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateCheckPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };

    var validateCheckVerificationCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginRules: {
        username: [
          { required: true, message: "请输入帐户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loginRules2: {

        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
      },
      //重置密码发送邮件
      resetPassEmail: {
        phoneOrEmail: undefined,
      },

      //1登录 2注册 3忘记密码
      loginStatus: 1,

      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      getUserId: getUserId(),
      rules: {
        username: [{ validator: validateUserName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validateCheckPass, trigger: "blur" }],
        phoneNumber: [{ validator: validateCheckPhone, trigger: "blur" }],
        smsVerificationCode: [
          { validator: validateCheckVerificationCode, trigger: "blur" },
        ],
      },
      dialogLoginVisible: true,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        platformType: "1",
        validateCaptcha: undefined,
      },

      xieyiObj: {},
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      inviter_code: "",
      //true是登录 false是注册
      LoginRegister: true,
      loginNoticeVisible: false,
      loginNoticeContent: undefined,
      quickLoginList: [],
      verifyStatus: false,
    };
  },
  computed: {
    ...mapState({
      //查询网站系统配置
      setting: (state) => state.index.setting,
      webSetting: (state) => state.index.webSetting,
    }),
  },

  mounted() {
    // // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = "accountLogin";
    } else if (this.setting.phoneLogin) {
      this.activeName = "phoneLogin";
    } else {
      this.activeName = "accountLogin";
    }
  },
  created() {
    //有推广码的话授权
    if (localStorage.getItem("inviterCode")) {
      this.loginForm.inviterCode = localStorage.getItem("inviterCode");
    }

    //加载协议
    this.loadXieYi();

    //加载登录公告
    this.queryLoginNotice();

    //加载快捷登录配置
    this.loadQuickLogin();
  },
  methods: {
    /**
     * 验证成功
     */
    success(params) {
      //验证成功
      this.verifyStatus = true;

      this.loginForm.validateCaptcha = params.captchaVerification;
    },

    /**
     * 点击验证
     */
    useVerify() {
      this.$refs.verify.show();
    },

    /**
     * 加载快捷登录配置
     */
    loadQuickLogin() {
      new Promise((resolve, rejust) => {
        getQuickLogin()
          .then((res) => {
            this.quickLoginList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询首页弹框公告
     */
    queryLoginNotice() {
      getLoginNotice().then((response) => {
        if (response.result) {
          this.loginNoticeVisible = true;
          this.loginNoticeContent = response.result.content;
        }
      });
    },

    /**
     * 重置密码 发送邮件
     */
    resetPassSendEmail() {
      new Promise((resolve, rejust) => {
        resetPwdVerifyCode(this.resetPassEmail)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "发送成功",
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 重置密码
     */
    resetPassword() {
      new Promise((resolve, rejust) => {
        pwdReset(this.resetPassEmail)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 账号密码登录
     */
    handleLogin(formName) {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/index");
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },

    /**
     * 手机号登录
     */
    phoneLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/phoneLogin", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/index");
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },

    /**
     * 注册
     *
     * */
    handleRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/register", this.loginForm)
            .then(() => {
              this.$store.dispatch("user/login", this.loginForm).then(() => {
                Toast.success({
                  message: "注册成功",
                });
                this.dialogLoginVisible = false;

                this.$router.push("/index");
              });
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },

    /**
     * 切换登录
     */
    handleClick() {
      this.$refs.loginForm.resetFields();
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    closeLogin() {
      this.dialogLoginVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style>
.login_p {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(../../../assets/login_bg2.png);
  background-size: cover;
  background-position: 50%;
}

.login_icon {
  width: 32px;
}
</style>
