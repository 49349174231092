  /**
   * 模块基本元素：
   * [s]  样式
   * [sc] 样式内容 [t样式名称 p 样式图标]
   * [m]  模块间距 [true有 false无]
   * [c]  列数 [4列 5列表]
   * [bg] 背景 [1默认 2隐藏 3自定义]
   * [bt] 背景类型 [1颜色填充 2背景图片 3透明] 
   * [bc] 背景颜色
   * [bi] 背景图片
   * [fc] 文字颜色
   */
  const base = {
      s: 0,
      margins: 10,
      round: 0,
      m: true,
      c: 4,
      fc: '',
      pageBg: { bgColor: '#FFFFFF', bgImage: '', bgType: 1, },
  }


  //页面数据 
  const pageData = [


      /**
       * 搜索模块
       * name 名称 ｜ type 1 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | column 表单模块 ｜ data 表单数据
       */
      {
          name: '头部模块',
          type: 201,
          base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }, { t: '样式-3', p: '' }], ...base },
          style: { bgcolor: '#fff', color: '#333', text: '我是文字' },
          list: [],
      },
      /**
       * 轮播图模块
       * name 名称 ｜ type 2 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | column 表单模块 ｜ data 表单数据
       */
      {
          name: '轮播模块',
          type: 202,
          base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }, { t: '样式-3', p: '' }, ], ...base },
          style: { bgcolor: '#3B8CFE', color: '#ffffff', text: '我是文字' },
          list: [],
      },
      /**
       * 导航模块
       * name 导航名称 ｜ type 导航标识 ｜ base 基础样式 ｜ list 导航列表 | max 最多添加15｜
       */
      //   {
      //       name: '导航模块',
      //       type: 203,
      //       base: {...base, sc: [{ t: '样式一', p: '' }] },
      //       max: 15,
      //   },
      /**
       * 商品模块
       * name 名称 ｜ type 4 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | max 最多添加4｜
       * base [t 商品标题 sth显示特惠 sjg显示价格 sgm 显示购买 sjs 显示结束时间]
       */
      {
          name: '商品模块',
          type: 204,
          base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }, { t: '样式-3', p: '' }], ...base },
          moduleBg: { bgColor: '#2db1ff', fzColor: '#1479ff', fontColor: '#FFFFFF', },
          max: 4,
      },
      /**
       * 商品模块
       * name 名称 ｜ type 5 标识 ｜ base 基础样式 ｜ title 标题 ｜ list 商品列表 | max 最多添加4｜
       * base [t 商品标题 sth显示特惠 sjg显示价格 sgm 显示购买 sjs 显示结束时间]
       */
      {
          name: '商品推荐',
          type: 205,
          base: { sc: [{ t: '样式-1', p: '' }], ...base, },
          moduleBg: { bgColor: '#2db1ff', fzColor: '#1479ff', fontColor: '#FFFFFF', },
          max: 4,
      },

      {
          name: '首页公告',
          type: 206,
          base: { sc: [{ t: '样式-1', p: '' }], ...base, },
          moduleBg: { bgColor: '#2db1ff', fzColor: '#1479ff', fontColor: '#23262A', },
          max: 4,
      },

      {
          name: '图文导航',
          type: 208,
          base: { sc: [{ t: '样式-1', p: '' }], ...base },
          moduleBg: { bgColor: '#2db1ff', fzColor: '#1479ff', fontColor: '#23262A', },
          max: 4,
          list: [],
      },
      {
          name: '底部模块',
          type: 207,
          base: { sc: [{ t: '样式-1', p: '' }, { t: '样式-2', p: '' }], ...base },
          data: { title1: "底部模块", path1: "", title2: "联系客服", path2: "" },
          max: 4,
          list: [],
      },
      //   {
      //     name: '悬浮客服',
      //     type: 209,
      //     base: { sc: [{ t: '样式-1', p: '' },{ t: '样式-2', p: '' }], ...base },
      //     list: [],
      //   },
  ]

  const control = [{
      name: '公共设置',
      type: 200,
      base: { pageBg: {...base }, ...base },
  }, ]

  export default {

      pageData: pageData,
      controlData: control,
  }