<template>
  <div @click.stop>
    <a-drawer
      :title="attrData.name"
      :visible="visibleStatus"
      :width="500"
      @close="visibleStatus = false"
    >
      <div style="25px">
        <div class="diy_right_title">展示设置</div>

        <div class="diy_right_centent">
          <el-form label-position="left">
            <el-form-item label="样式风格：">
              <el-radio-group
                v-model="style_index"
                @change="triggerParentMethod"
              >
                <el-radio
                  :label="index"
                  v-for="(item, index) in this.attrData.base.sc"
                  :key="index"
                >
                  {{ item.t }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>

        <a-divider></a-divider>

        <div class="diy_right_title">内容设置</div>

        <div class="diy_right_centent">
          <el-form>
            <el-form-item>
              <!-- 头部 -->
              <AttrHead :attr="attrData" v-if="attrData.type == 201" />

              <!-- 轮播图 -->
              <AttrBanner :attr="attrData" v-if="attrData.type == 202" />

              <!-- 广告 -->
              <AttrGuangGao :attr="attrData" v-if="attrData.type == 208" />

              <!-- 底部设置 -->
              <AttrFooter :attr="attrData" v-if="attrData.type == 207" />

              <!-- 首页公告 -->
              <AttrCommon :attr="attrData" v-else />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
// import "@/assets/css/attr.scss";

import AttrHead from "@/views/template/pc/module/header/style/index.vue";

//轮播图
import AttrBanner from "@/views/template/pc/module/banner/style/index.vue";

// import AttrNotice from "@/views/template/mobile/module/Notice/style/index.vue";

//广告
import AttrGuangGao from "@/views/template/pc/module/guanggao/style/index.vue";

//广告
import AttrFooter from "@/views/template/pc/module/footer/style/index.vue";

export default {
  // props: ["attrData"],
  components: {
    AttrHead,

    // AttrNotice,

    AttrBanner,

    AttrGuangGao,

    AttrFooter
  },
  data() {
    return {
      visibleStatus: false,
      attrData: [],
      style_index: undefined,
      deleteStatus: true
    };
  },

  mounted() {},
  methods: {
    //点击模块
    childMethod(data) {
      this.visibleStatus = true;

      this.deleteStatus = true;

      this.attrData = data;
      this.style_index = data.base.s;
      this.$forceUpdate();
    },

    //隐藏模块
    deleteMethod() {
      this.deleteStatus = false;
    },

    triggerParentMethod(k) {
      // 通过 $emit 向父组件发送事件
      // console.log(JSON.stringify(this.attrData))
      this.$emit("child-right", JSON.stringify(this.attrData), k);
    }
  }
};
</script>
<style>
.ant-drawer-body {
  padding: 0px !important;
}
</style>
