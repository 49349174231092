<template>
  <div class="balance-page">
    <a-card>
      <a-tabs>
        <a-tab-pane tab="安全中心" key="1">
          <div class="card-item">
            <p class="card-item-title">登录密码</p>
            <div class="card-item-content">
              互联网账号存在被盗风险，建议您定期更改密码。
            </div>
            <el-button type="text" @click="dialogVisible.pwd = true">
              修改
            </el-button>
          </div>

          <div class="card-item">
            <p class="card-item-title">邮箱绑定</p>
            <div
              class="card-item-content"
              v-if="email"
              style="color: #ff0000; font-weight: 600"
            >
              您已经绑定邮箱
            </div>
            <div class="card-item-content" v-else>
              您的邮箱： 未绑定
              。绑定认证后可用于邮箱找回密码、接收订单提醒等，保障您的账户安全
            </div>

            <el-button type="text" @click="dialogVisible.email = true">
              绑定
            </el-button>
          </div>

          <div class="card-item">
            <p class="card-item-title">手机绑定</p>
            <div
              class="card-item-content"
              v-if="phone"
              style="color: #ff0000; font-weight: 600"
            >
              您已经绑定手机。
            </div>
            <div class="card-item-content" v-else>
              您的手机： 未绑定
              。绑定认证后可用于手机找回密码、接收手机动态验证码等，保障您的账户安全
            </div>
            <el-button type="text" @click="dialogVisible.phone = true">
              绑定
            </el-button>
          </div>

          <div class="card-item">
            <p class="card-item-title">实名认证</p>
            <div
              class="card-item-content"
              v-if="isCardNo"
              style="color: #ff0000; font-weight: 600"
            >
              您已经实名认证。
            </div>
            <div class="card-item-content" v-else>未实名认证</div>
            <el-button type="text" @click="dialogVisible.auth = true">
              绑定
            </el-button>
          </div>
        </a-tab-pane>

        <a-tab-pane tab="接口密钥" key="2">
          <div class="card-item">
            <p class="card-item-title" style="width: 70px">接口状态</p>

            <el-switch
              v-model="apiStatus"
              :active-value="1"
              :inactive-value="0"
              @change="updataApiStatus()"
            >
            </el-switch>
          </div>

          <div class="card-item">
            <p class="card-item-title" style="width: 70px">用户编号</p>
            {{ userid }}
          </div>

          <div class="card-item">
            <p class="card-item-title" style="width: 70px">接口密钥</p>
            <el-col :span="10">
              <el-input v-model="secretKey">{{ secretKey }}</el-input>
            </el-col>
            <span style="padding-left: 10px"></span>
            <el-button type="primary" @click="createKey()" size="medium" v-preventClick>
              生成
            </el-button>
          </div>

          <div class="card-item">
            <p class="card-item-title" style="width: 70px">接口文档</p>

            <el-button type="text">
              <a
                href="https://www.showdoc.com.cn/2285565147915645"
                target="_blank"
              >
                在线文档
              </a>
            </el-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>

    <el-dialog
      title="密码修改"
      :visible.sync="dialogVisible.pwd"
      width="40%"
      :modal-append-to-body="false"
    >
      <div class="form-group" style="margin-top: 20px">
        <el-form label-position="right" label-width="100px">
          <el-form-item label="旧密码" class="label" prop="deliveryPerson">
            <el-input
              v-model="pwd.oldPwd"
              style="width: 400px"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" class="label" prop="deliveryPerson">
            <el-input
              v-model="pwd.newPwd"
              style="width: 400px"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlePwdReset" v-preventClick>确 定</el-button>
        <el-button @click="dialogVisible.pwd = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="邮箱绑定" :visible.sync="dialogVisible.email" width="40%">
      <div class="form-group" style="margin-top: 20px">
        <template v-if="email">
          <el-form label-position="right" label-width="100px">
            <el-form-item label="原邮箱" class="label" prop="deliveryPerson">
              <p>{{ email }}</p>
            </el-form-item>
          </el-form>
          <el-form :inline="true" label-width="100px">
            <el-form-item label="验证码" class="label" prop="deliveryPerson">
              <el-input
                v-model="pwdFrom.removecode"
                style="width: 240px"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="handleEmailCode" v-preventClick>
                获取验证码
              </el-button>
            </el-form-item>
          </el-form>

          <el-form label-position="right" label-width="100px">
            <el-form-item>
              <el-button type="danger" size="medium" @click="handleEmailMove" v-preventClick>
                解绑
              </el-button>
            </el-form-item>
          </el-form>
        </template>

        <el-form label-position="right" label-width="100px">
          <el-form-item label="邮箱号" class="label" prop="deliveryPerson">
            <el-input
              v-model="pwdFrom.newEmail"
              style="width: 240px"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-width="100px">
          <el-form-item label="验证码" class="label" prop="deliveryPerson">
            <el-input v-model="pwdFrom.code" style="width: 240px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="medium"
              @click="handleEmailVerifyCode"
              v-preventClick
            >
              获取验证码
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelEmailUpdate" v-preventClick>确 定</el-button>

        <el-button @click="dialogVisible.email = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="手机号绑定"
      :visible.sync="dialogVisible.phone"
      width="40%"
    >
      <div class="form-group" style="margin-top: 20px">
        <el-form label-position="right" label-width="100px" v-if="phone">
          <el-form-item label="原手机号" class="label" prop="deliveryPerson">
            <p>{{ phone }}</p>
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-width="100px" v-if="phone">
          <el-form-item label="验证码" class="label" prop="deliveryPerson">
            <el-input
              v-model="phoneFrom.removecode"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlePhoneCode" v-preventClick>
              获取验证码
            </el-button>
          </el-form-item>
        </el-form>
        <el-form label-position="right" label-width="100px" v-if="phone">
          <el-form-item>
            <el-button type="primary" click="handlePhoneMove" v-preventClick>解绑</el-button>
          </el-form-item>
        </el-form>
        <el-form label-position="right" label-width="100px">
          <el-form-item label="手机号" class="label" prop="deliveryPerson">
            <el-input
              v-model="phoneFrom.newPhone"
              style="width: 240px"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-width="100px">
          <el-form-item label="验证码" class="label" prop="deliveryPerson">
            <el-input v-model="phoneFrom.code" style="width: 240px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlePhoneVerifyCode" v-preventClick>
              获取验证码
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelPhoneUpdate" v-preventClick>确 定</el-button>

        <el-button type="primary" @click="dialogVisible.phone = false">
          取 消
        </el-button>
      </span>
    </el-dialog>

    <el-dialog title="实名认证" :visible.sync="dialogVisible.auth">
      <div class="form-group" style="margin-top: 20px">
        <el-form label-position="right" label-width="100px">
          <el-form-item label="姓名" class="label" prop="deliveryPerson">
            <el-input v-model="authFrom.realName" style="width: 240px">
            </el-input>
          </el-form-item>

          <el-form-item label="身份证号" class="label" prop="deliveryPerson">
            <el-input v-model="authFrom.cardNo" style="width: 240px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelCardAuthUpdate" v-preventClick>
          确 定
        </el-button>

        <el-button type="primary" @click="dialogVisible.auth = false">
          取 消
        </el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改账号" :visible.sync="dialogUpdateAccountVisible">
      <div class="form-group" style="margin-top: 20px">
        <el-form label-position="right" label-width="100px">
          <el-form-item label="登录账号">
            <el-input v-model="newUsername" style="width: 240px"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmUpdateAccount" v-preventClick>
          确 定
        </el-button>

        <el-button type="primary" @click="dialogUpdateAccountVisible = false">
          取 消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { avatar } from "@/api/user";

import { updateUsername, verify_code } from "@/api/user";

export default {
  data() {
    return {
      newUsername: undefined,
      dialogUpdateAccountVisible: false,
      activeName: "1",
      pwd: {
        oldPwd: "",
        newPwd: "",
      },
      pwdFrom: {
        removecode: "",
        newEmail: "",
        code: "",
      },
      phoneFrom: {
        removecode: "",
        newPhone: "",
        code: "",
      },
      authFrom: {
        realName: undefined,
        cardNo: undefined,
      },
      dialogVisible: {
        pwd: false,
        email: false,
        phone: false,
        auth: false,
      },
    };
  },
  components: {
    // Card,
  },
  computed: {
    ...mapState({
      balanceAll: (state) => state.commission.balanceAll,
      balanceAllowTransfer: (state) => state.commission.balanceAllowTransfer,
      balanceIsRejected: (state) => state.commission.balanceIsRejected,
      balanceIsTransfering: (state) => state.commission.balanceIsTransfering,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      secretKey: (state) => state.user.secretKey,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
      branchId: (state) => state.user.branchId,
      levelName: (state) => state.user.levelName,
      levelNum: (state) => state.user.levelNum,
      userid: (state) => state.user.userid,
      apiStatus: (state) => state.user.apiStatus,
      isCardNo: (state) => state.user.isCardNo,
    }),
  },

  created() {
    this.$store.dispatch("user/info").then();
  },
  methods: {
    /**
     * 修改账号
     */
    modifyAccount() {
      this.newUsername = undefined;
      this.dialogUpdateAccountVisible = true;
    },

    /**
     * 确认修改账号
     */
    confirmUpdateAccount() {
      let data = {
        username: this.newUsername,
      };
      updateUsername(data)
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              message: "修改成功!",
              type: "success",
            });
            this.dialogUpdateAccountVisible = false;

            this.$store.dispatch("user/info").then();
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    async handleUploadHttpRequest(param) {
      const fileObj = param.file;
      const response = await avatar(fileObj);
      if (response.status == 0) {
        this.$store.commit("user/SET_AVATAR", response.result);
        // this.avatarPicUrl =
        // this.reasonImageListId.push(response.result.id)
      } else {
        this.$message({
          message: response.message,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    handleSettlement() {
      this.$router.push("/settlement");
    },
    handlePwdReset() {
      this.$store
        .dispatch("user/pwd_reset", {
          passwordOld: this.pwd.oldPwd,
          passwordNew: this.pwd.newPwd,
        })
        .then(() => {
          this.pwd.oldPwd = "";
          this.pwd.newPwd = "";
          this.dialogVisible.pwd = false;
        });
    },
    handleEmailCode() {
      this.$store.dispatch("user/email_code", {});
    },
    handleEmailMove() {
      this.$store
        .dispatch("user/email_move", { verifyCode: this.pwdFrom.removecode })
        .then(() => {
          this.$store.commit("user/SET_EMAIL", "");
        });
    },

    /**
     * 生成密钥
     */
    createKey() {
      this.$store.dispatch("user/generateKey", {}).then(() => {});
    },

    /**
     * 修改api状态
     */
    updataApiStatus() {},

    /**
     * 验证邮箱
     */
    handleEmailVerifyCode() {
      // this.$store.dispatch("user/verify_code", {
      //   email: this.pwdFrom.newEmail,
      // });

      new Promise((resolve, rejust) => {
        verify_code({
          email: this.pwdFrom.newEmail,
        })
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //邮箱修改
    handelEmailUpdate() {
      this.$store
        .dispatch("user/email_update", {
          email: this.pwdFrom.newEmail,
          verifyCode: this.pwdFrom.code,
        })
        .then(() => {
          this.$store.commit("user/SET_EMAIL", this.pwdFrom.newEmail);
          this.pwdFrom.newEmail = "";
          this.pwdFrom.code = "";
          this.pwdFrom.removecode = "";
          this.dialogVisible.email = false;
          this.dialogVisible.email = false;
        });
    },
    handlePhoneCode() {
      this.$store.dispatch("user/phone_remove_code", {});
    },

    //取消授权
    handlePhoneMove() {
      this.$store
        .dispatch("user/phone_remove", {
          verifyCode: this.phoneFrom.removecode,
        })
        .then(() => {
          this.$store.commit("user/SET_PHONE", "");
        });
    },

    //获取验证码
    handlePhoneVerifyCode() {
      this.$store.dispatch("user/phone_code", {
        phone: this.phoneFrom.newPhone,
      });
    },

    //手机号认证
    handelPhoneUpdate() {
      this.$store
        .dispatch("user/phone_update", {
          phone: this.phoneFrom.newPhone,
          verifyCode: this.phoneFrom.code,
        })
        .then(() => {
          this.$store.commit("user/SET_PHONE", this.phoneFrom.newPhone);
          // this.phone = this.phoneFrom.newPhone
          this.phoneFrom.code = "";
          this.phoneFrom.newPhone = "";
          this.phoneFrom.removecode = "";
          this.dialogVisible.phone = false;
        });
    },

    //实名认证
    handelCardAuthUpdate() {
      this.$store
        .dispatch("user/card_auth", {
          realName: this.authFrom.realName,
          cardNo: this.authFrom.cardNo,
        })
        .then(() => {
          this.$store.dispatch("user/info").then();
          this.authFrom.realName = "";
          this.authFrom.cardNo = "";
          this.dialogVisible.auth = false;
        });
    },
  },
};
</script>

<style scoped>
p {
  padding: 0px;
  margin: 0px;
}

.balance-page {
  display: flex;
  flex-direction: column;
}

.user-info {
  background: #fff;
  width: 100%;
  margin-bottom: 10px;
  /* padding: 10px; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-self: center;
}

.user-info-left {
  display: flex;
  width: 100%;
  padding: 10px 20px;
}

.user-info-right {
  display: flex;
}

.right-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 200px;
}

.user-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  margin-right: 20px;
  position: relative;
  width: 100%;
}

.user-item:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10px;
  right: 0px;
  background: #000;
}

.user-item:last-child:after {
  display: none;
}

.back {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
}

.back:nth-child(n) {
  margin-bottom: 10px;
}

.icon {
  /* width: 20px; */
  height: 26px;
  margin-right: 10px;
}

.title {
  font-size: 14px;
  margin-right: 30px;
}

.content {
  font-size: 12px;
}

.price {
  font-size: 10px;
  /* margin-top:4px; */
  color: #1b1b1b;
}

.avatar {
  width: 80px;
  height: 80px;

  border-radius: 50%;
}

.info-content {
  display: flex;
  flex-direction: column;
  height: 80px;
  align-items: center;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
  justify-self: start;
  margin-bottom: 10px;
  width: 100%;
}

p {
  font-size: #1b1b1b;
}

.card-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 30px 10px;
  box-sizing: border-box;
}

.card-item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0px;
  /* background: #707070; */
  opacity: 0.5;
  border-style: dashed;
  border-width: 1px;
  border-color: #e6e6e6;
}

.card-item:last-child::after {
  display: none;
}

.card-item-title {
  width: 100px;
  /* margin-right: 20px; */
  color: #1b1b1b;
  font-weight: 600;
}

.card-item-content {
  width: 100%;
  color: #1b1b1b;
}

.avatar-item {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 40px;
  width: 80px;
  height: 80px;
}

.avatar-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0px;
  height: 25px;
  line-height: 21px;
  background: rgba(0, 0, 0, 0.384);
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}
</style>
