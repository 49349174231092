<template>
  <div>
       <!-- 联系客服 -->
       <div class="coustom-server" style="top: 600px" v-if="this.setting.sideCustomerStatus">
      <div class="el-tooltip server-content" @click="toCustomer()">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoBAMAAACREs5gAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAtUExURUdwTPX6//T6//T6//b7//f7//X6//X7//H4//T5//v9/+r1/ziZ/8rk/5LM/zkDzM8AAAAJdFJOUwCUV+pxNbYb2GqpHEUAAAGjSURBVCjPnZM7SwNBEMcnZ3JneeALYnOIImpjtPCRRlAErQQVhDRXKcbmRBDEJhK1SRUD4mQrX8Vdp6K4jH4AwcsXCH4C/RDOPRNjqvxh2d3fzfxvbmcPoANt9G/3rrUwrcfx1PeXpp2Tkc3hvLPTDNedAT9j0TEaUIlj0tcNOn4XrVLzeryscp42vDTqecUBB7xS8lzCqRcRlZc2Qcv7lZ2xWyE0uLW4iEAGqKEFz9p8SG8gVQno0SR0O3ZAbQt2g5JXdFit+kLbNmHM9L5gem4N5my0EZHxPXRVMpyPCApvOc62q1gGFdGAMbyCBMbyNzrTS+9xLEgGtAzdTdRSmul3OBpURSldIYTLMwRUliEhUDIRrsBX9pVMRRmSkrBG4t2V8gFUIXQ4xgtISUk/JN8+BD17LzFAKfLpZ4neal+fdRIm5GYz4QnvkaCfT5foyYLBhagbJWLVeTyClrWixlEkAxIPcecjyB3KzTRoZtmD3E2tFNvCELsEnVdf2txMba/Qhm69Wv/hPuktuRaklmmqJWziqegX8VeHJTrv6+TX+QVnR9FNMgbAPgAAAABJRU5ErkJggg=="
           draggable="false" />
        <p>联系客服</p>
      </div>

      <div class="server-content">
        <div>
          <el-backtop>
            <div class="pBacktop">
              <i class="el-icon-caret-top"></i>
            </div>
          </el-backtop>
        </div>

        <div>返回顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Img from "../attr/img.vue";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      webLogo: "./logo.png",
      loginStatus: false,
      goodsName: ""
    };
  },
  computed: {},
  methods: {

  }
};
</script>

<style>

</style>
